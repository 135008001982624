// import { Language } from 'enums/enums';
import React, { useState } from 'react';
import { Language } from '../../enums/enums';

export const LanguageContext = React.createContext({
  language: Language.EN,
  setLanguage: (language: Language) => {},
});

interface LanguageProviderProps {
  children: JSX.Element;
}

export const LanguageProvider = ({
  children,
}: LanguageProviderProps): JSX.Element => {
  const [language, setLanguage] = useState(Language.EN);

  const memoizedValue = React.useMemo(
    () => ({ language, setLanguage }),
    [language, setLanguage],
  );

  return (
    <LanguageContext.Provider value={memoizedValue}>
      {children}
    </LanguageContext.Provider>
  );
};
