import { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuthStore } from '../../../contexts/Auth/AuthProvider';

export const ProfileAvatar = (props) => {
  const [state] = useAuthStore();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (state?.profilePicture) {
      setImage(window.URL.createObjectURL(state.profilePicture));
    }
  }, [state]);

  if (image === null) {
    return <Avatar icon={<UserOutlined />} />;
  }
  return <Avatar src={image} />;
};

export default ProfileAvatar;
