import { create } from 'zustand';

export const useSideNavigationKeysStore = create((set) => ({
  openKeys: [],
  selectedKey: '',
  addOpenKeys: (newOpenKeys) =>
    set(({ openKeys }) => ({
      openKeys: [...openKeys, ...newOpenKeys],
    })),
  setOpenKeys: (newOpenKeys) => set({ openKeys: newOpenKeys }),
  setSelectedKey: (newSelectedKey) => set({ selectedKey: newSelectedKey }),
}));
