import { Layout } from 'antd';
import { SideNavigation } from 'components/layout/SideNavigation/SideNavigation';
import { TopBar } from 'components/layout/TopBar/TopBar';
import { CustomFooter } from '../Footer/CustomFooter';

const { Content } = Layout;

interface RootLayoutProps {
  children: JSX.Element[];
}

export const RootLayout = ({ children }: RootLayoutProps): JSX.Element => {
  return (
    <Layout className="layout__wrapper">
      <TopBar />
      <Layout>
        <SideNavigation />
        <div style={{ marginTop: '64px', marginLeft: '350px' }}>
          <Content>
            <div className="layout__content" style={{ minHeight: 360 }}>
              {children}
            </div>
          </Content>
          <CustomFooter />
        </div>
      </Layout>
    </Layout>
  );
};
