import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { LanguageProvider } from './contexts/Language/LanguageProvider';
// import { LanguageProvider } from 'contexts/Language/LanguageProvider';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth/AuthProvider';

import '@forrs-ems/doc-tool-components/dist/styles.css';
import { msalConfig } from './services/auth/authConfig';
import MsalAuthProvider from './services/auth/MsalAuthProvider';

const queryClient = new QueryClient();

const msalInstance = new PublicClientApplication(msalConfig);

const app = (
  <React.StrictMode>
    <AuthProvider>
      <MsalProvider instance={msalInstance}>
        <MsalAuthProvider>
          <LanguageProvider>
            <QueryClientProvider client={queryClient}>
              <Router>
                <App />
              </Router>
            </QueryClientProvider>
          </LanguageProvider>
        </MsalAuthProvider>
      </MsalProvider>
    </AuthProvider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
