import { useEffect, useState } from 'react';
import { Layout, Menu, Tooltip } from 'antd';

import { SideNavigationLinkLogo } from './SideNavigationLinkLogo/SideNavigationLinkLogo';
import {
  onUpdateSideNavigationState,
  useBaseUrl,
  useSideNavigationDocumentationItems,
} from '@forrs-ems/doc-tool-components';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSideNavigationKeysStore } from '../../../store/useSideNavigationKeysStore';

const { Sider } = Layout;

const onItemClick = (setSelectedKey) => (key) => () => setSelectedKey(key);
const onOpenChange = (setOpenKeys) => (e) => {
  setOpenKeys(e);
};

export const SideNavigation = () => {
  const baseUrl = useBaseUrl();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const documentationItems = useSideNavigationDocumentationItems({
    useQuery,
    SubMenu: Menu.SubMenu,
    Link: Link,
    baseUrl: baseUrl,
    hasDocumentationLevel: false,
    history: history,
    Tooltip: Tooltip,
    collapsed,
  });

  const { openKeys, addOpenKeys, selectedKey, setOpenKeys, setSelectedKey } =
    useSideNavigationKeysStore(
      ({
        openKeys,
        addOpenKeys,
        selectedKey,
        setOpenKeys,
        setSelectedKey,
      }) => ({
        openKeys,
        addOpenKeys,
        selectedKey,
        setOpenKeys,
        setSelectedKey,
      }),
    );

  // update the openKeys for when the user refreshes the page
  useEffect(() => {
    if (pathname) {
      onUpdateSideNavigationState({
        openKeys,
        addOpenKeys,
        setSelectedKey,
      })(pathname)();
    }
  }, []);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      width={300}
      style={{
        background: '#00493b',
        height: 'calc(100vh - 64px)',
        overflowY: 'auto',
        position: 'fixed',
        overscrollBehavior: 'contain',
        top: '64px',
      }}
    >
      <Menu
        className="main-menu__wrapper"
        theme="dark"
        mode="inline"
        onOpenChange={onOpenChange(setOpenKeys)}
        openKeys={openKeys}
        selectedKeys={[selectedKey]}
        items={collapsed ? [] : documentationItems}
        onSelect={({ key }) => setSelectedKey(key.replaceAll(' ', '%20'))}
        onClick={(e) => {
          setSelectedKey(e.key);
          // !! HACK
          // only when a user clicks on the tooltip
          if (e.domEvent.target.closest('.ant-tooltip-inner')) {
            history.push('/' + e.key);
          }
        }}
      />
    </Sider>
  );
};
