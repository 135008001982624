import './App.css';

import { Route, Switch } from 'react-router-dom';
import { DocumentationPageContainer } from './components/pages/DocumentationPage/DocumentationPageContainer';
import { RootLayout } from './components/layout/RootLayout/RootLayout';
import { NotFound } from './components/pages/NotFound/NotFound';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#1bb470',
          colorInfo: '#1bb470',
          fontFamily: 'Lato, sans-serif',
        },
        components: {
          Layout: {
            headerBg: '#fff',
            siderBg: '#fff',
            triggerBg: '#00493b',
            bodyBg: '#f1f0eb',
            footerBg: '#f1f0eb',
          },
          Menu: {
            darkItemBg: '#00493b',
            darkSubMenuItemBg: '#336d62',
            itemMarginInline: '0px',
            // itemSelectedColor: 'red',
          },
        },
      }}
    >
      <RootLayout>
        <Switch>
          <Route exact path="/">
            <DocumentationPageContainer />
          </Route>
          <Route path="/docu/:slug+">
            <DocumentationPageContainer />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </RootLayout>
    </ConfigProvider>
  );
}

export default App;
