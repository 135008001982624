import { Button, Layout } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { US, DE } from 'country-flag-icons/react/3x2';
import { useContext } from 'react';
import { Language } from 'enums/enums';
import { LanguageContext } from 'contexts/Language/LanguageProvider';
import UserMenu from './UserMenu';
import { SideNavigationLinkLogo } from '../SideNavigation/SideNavigationLinkLogo/SideNavigationLinkLogo';

const { Header } = Layout;

const onSetLanguage =
  (language: Language, setLanguage: (value: Language) => void) => () =>
    setLanguage(language === Language.DE ? Language.EN : Language.DE);

export const TopBar = (): JSX.Element => {
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <Header
      className="header__wrapper"
      style={{
        display: 'flex',
        justifyContent: 'start',
        position: 'fixed',
        width: '100%',
        zIndex: 10,
        borderBottom: '1px solid #cbcbcb',
      }}
    >
      <SideNavigationLinkLogo />
      {/*<div className="header-menu__wrapper">*/}
      {/*  <div className="header-menu__item">*/}
      {/*    <SearchOutlined />*/}
      {/*  </div>*/}
      {/*  <div className="header-menu__item">*/}
      {/*    <Button*/}
      {/*      ghost*/}
      {/*      style={{ width: '2em', padding: 0, border: 0 }}*/}
      {/*      onClick={onSetLanguage(language, setLanguage)}*/}
      {/*    >*/}
      {/*      {language === Language.DE ? (*/}
      {/*        <DE title="Germany" className="de-flag" />*/}
      {/*      ) : (*/}
      {/*        <US title="United States" className="us-flag" />*/}
      {/*      )}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="header__separator" />*/}
      <div style={{ marginLeft: 'auto' }}>
        <UserMenu />
      </div>
    </Header>
  );
};
