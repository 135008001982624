import { DocumentationPageBreadcrumb } from './DocumentationPageBreadcrumb';
import {
  DocumentationPage,
  useBaseUrl,
  HomeDocumentationPage,
  onUpdateSideNavigationState,
} from '@forrs-ems/doc-tool-components';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LanguageContext } from 'contexts/Language/LanguageProvider';
import { useSideNavigationKeysStore } from '../../../store/useSideNavigationKeysStore';
import { HashLink } from 'react-router-hash-link';
import ScrollToTopButton from './ScrollToTopButton';

// eslint-disable-next-line react/prop-types
export const DocumentationPageContainer = () => {
  const { language } = useContext(LanguageContext);
  const baseUrl = useBaseUrl();
  const [localUrl, setLocalUrl] = useState();
  const { pathname } = useLocation();
  const history = useHistory();
  const addOpenKeys = useSideNavigationKeysStore((state) => state.addOpenKeys);
  const openKeys = useSideNavigationKeysStore((state) => state.openKeys);
  const setSelectedKey = useSideNavigationKeysStore(
    (state) => state.setSelectedKey,
  );

  // Show only for the files and not for the directories content
  // Also, if the user clicks on a new directory, the previous file content should be kept open
  const [onlyFilesPathname, setOnlyFilesPathname] = useState('');
  useEffect(() => {
    if (!pathname.endsWith('/')) {
      setOnlyFilesPathname(pathname);
    }
    // every time the user clicks on a new navigation item and the url changes, scroll to the top
    window.scroll({ top: 0 });
  }, [pathname]);

  useEffect(() => {
    if (baseUrl.includes('localhost')) {
      setLocalUrl('http://localhost:3002');
    } else {
      setLocalUrl(null);
    }
  }, [baseUrl]);

  // only for the home level show the index file
  if (pathname === '/') {
    return (
      <HomeDocumentationPage
        useQuery={useQuery}
        pathname={pathname}
        localUrl={localUrl}
        language={language}
        onClickAnchor={onUpdateSideNavigationState({
          openKeys,
          addOpenKeys,
          setSelectedKey,
          hasDocumentationLevel: false,
        })}
        Link={Link}
        baseUrl={baseUrl}
        HashLink={HashLink}
      />
    );
  }

  return (
    <>
      {/*<DocumentationPageBreadcrumb />*/}
      <DocumentationPage
        pathname={onlyFilesPathname}
        useQuery={useQuery}
        Link={Link}
        HashLink={HashLink}
        localUrl={localUrl}
        language={language}
        onClickAnchor={onUpdateSideNavigationState({
          openKeys,
          addOpenKeys,
          setSelectedKey,
          hasDocumentationLevel: false,
        })}
        baseUrl={baseUrl}
      />
      <ScrollToTopButton />
    </>
  );
};
