import { Dropdown, Row } from 'antd';
import ProfileAvatar from './ProfileAvatar';
import { useMsal } from '@azure/msal-react';
import { useAuthStore } from '../../../contexts/Auth/AuthProvider';

export const UserMenu = (props) => {
  const [state, dispatch] = useAuthStore();
  const { instance } = useMsal();

  function handleLogout(instance) {
    dispatch({ type: 'LOGOUT', payload: null });
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  const items = [
    {
      key: 'logout',
      onClick: () => handleLogout(instance),
      label: 'Logout',
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <Row align="middle">
        <div className="header__avatar-name">{state?.userData?.name || ''}</div>
        <ProfileAvatar />
      </Row>
    </Dropdown>
  );
};

export default UserMenu;
