import { Link } from 'react-router-dom';

export const NotFound = (): JSX.Element => {
  return (
    <div>
      <h2>404 Not Found</h2>
      <Link to="/">Back to Home</Link>
    </div>
  );
};
