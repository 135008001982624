import { Layout, Typography } from 'antd';

export const CustomFooter = (): JSX.Element => {
  const { Footer } = Layout;
  const { Text } = Typography;
  return (
    <Footer style={{ textAlign: 'center' }}>
      <Text type="secondary">© FORRS Partners GmbH</Text>
    </Footer>
  );
};
