import { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  authData: JSON.parse(localStorage.getItem('authData')),
  userData: JSON.parse(localStorage.getItem('userData')),
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AUTHDATA':
      localStorage.setItem('authData', JSON.stringify(action.payload));
      return { ...state, authData: action.payload };

    case 'UPDATE_USERDATA':
      localStorage.setItem('userData', JSON.stringify(action.payload));
      return { ...state, userData: action.payload };

    case 'UPDATE_PROFILEPICTURE':
      return { ...state, profilePicture: action.payload };

    case 'LOGOUT':
      localStorage.clear();
      return {};

    default:
      return { ...state };
  }
};

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const isLoggedIn = state?.auth?.accessToken || false;

  return (
    <AuthContext.Provider value={[state, dispatch, isLoggedIn]}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthStore = () => useContext(AuthContext);
