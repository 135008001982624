import { useState } from 'react';

import { Button, Space, Row, Col, Typography } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useAuthStore } from '../../../contexts/Auth/AuthProvider';
import useTimeout from '../../../utils/useTimeout';
const { Text } = Typography;

export const AuthLoadingPage = () => {
  const [showReload, setShowReload] = useState(false);
  const [state, dispatch] = useAuthStore();
  const { instance } = useMsal();

  function handleLogout(instance) {
    dispatch({ type: 'LOGOUT', payload: null });
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  useTimeout(() => setShowReload(true), 3000);
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: '100vh', background: '#ccc' }}
    >
      <Col>
        <Space align="center" direction="vertical" size="large">
          {showReload && (
            <>
              <Text>
                There has been an unexpected error during login. This might
                happen if you accidentally use a wrong Microsoft Account.
              </Text>
              <Text level={3} strong>
                Please logout and try again. Make sure to use the correct
                Microsoft Account enabled for this application.
              </Text>
              <Button
                type="primary"
                danger
                onClick={() => {
                  handleLogout(instance);
                  window.location.reload();
                }}
              >
                Logout
              </Button>
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default AuthLoadingPage;
