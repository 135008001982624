import { Link } from 'react-router-dom';
import logo from 'assets/FORRS_Gryt_MDM_DATA_RGB.svg';
import { useSideNavigationKeysStore } from '../../../../store/useSideNavigationKeysStore';

export const SideNavigationLinkLogo = () => {
  const { setOpenKeys, setSelectedKey } = useSideNavigationKeysStore(
    ({ setOpenKeys, setSelectedKey }) => ({ setOpenKeys, setSelectedKey }),
  );

  return (
    <Link
      to="/"
      style={{
        background: '#fff',
        display: 'flex',
        height: '64px',
        padding: '0 24px',
        width: '260px',
      }}
      onClick={() => {
        setSelectedKey('');
        setOpenKeys([]);
      }}
    >
      <img
        alt="Logo"
        src={logo}
        style={{
          alignSelf: 'center',
          height: '40px',
          width: 'auto',
        }}
      />
    </Link>
  );
};
