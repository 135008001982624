import { useState, useEffect } from 'react';
import { Button } from 'antd';

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '12px',
      }}
    >
      {isVisible && (
        <Button
          onClick={scrollToTop}
          style={{
            background: '#1bb470',
            color: 'white',
          }}
        >
          Go to Top
        </Button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
