import { useEffect, useState } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { graphConfig, loginRequest, accessTokenRequest } from './authConfig';

import PropTypes from 'prop-types';
import { useAuthStore } from '../../contexts/Auth/AuthProvider';
import AuthLoadingPage from '../../components/pages/Auth/AuthLoadingPage';
import { LoginPage } from '../../components/pages/Login/LoginPage';

export async function callMsGraphData(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function callMsGraphPhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMePhotoEndPoint, options)
    .then((response) => response)
    .catch((error) => console.log(error));
}

const initializeSignIn = async (instance) => {
  await instance.handleRedirectPromise();

  const accounts = instance.getAllAccounts();
  if (accounts.length === 0) {
    await instance.loginRedirect(loginRequest);
  }
};

const MsalAuthProvider = ({ children }) => {
  const [state, dispatch] = useAuthStore();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [error, setError] = useState(false);

  // Redirection Logic
  useEffect(() => {
    const signIn = async () => {
      try {
        if (inProgress === InteractionStatus.None && !isAuthenticated) {
          console.log(
            'Effect: inProgress === InteractionStatus.None && !isAuthenticated',
          );
          dispatch({ type: 'LOGOUT', payload: null });
          await initializeSignIn(instance);
        }
      } catch (error) {
        console.error('Error in signIn:', error);
      }
    };

    signIn();
  }, [inProgress, isAuthenticated, instance, dispatch]);

  // Acquire Token Logic
  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      console.log(
        'Effect: inProgress === InteractionStatus.None && isAuthenticated',
      );
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {
          console.log({ message: 'login', response: response });

          // Update ProfilePicture
          callMsGraphPhoto(response.accessToken)
            .then((response) => response.blob())
            .then((data) => {
              if (data !== null)
                dispatch({ type: 'UPDATE_PROFILEPICTURE', payload: data });
            })
            .catch((e) => {
              console.log(e);
              setError(true);
            });

          instance
            .acquireTokenSilent({ ...accessTokenRequest, account: accounts[0] })
            .then((response) => {
              console.log({ message: 'accessToken', response: response });
              dispatch({ type: 'UPDATE_AUTHDATA', payload: response });
            });
        })
        .catch((e) => {
          console.log(e);
          setError(true);
        });
    }
  }, [inProgress, isAuthenticated, instance, accounts, dispatch]);

  // if (error) {
  //   console.log({
  //     a: isAuthenticated,
  //     b: accounts,
  //     c: state?.authData,
  //     d: inProgress,
  //   });
  //   return <AuthLoadingPage />;
  // }

  if (!isAuthenticated || !state?.authData?.accessToken) {
    console.log({
      a: isAuthenticated,
      b: accounts,
      c: state?.authData,
      d: inProgress,
    });
    return <AuthLoadingPage />;
  }

  // if (!isAuthenticated) {
  //   return <LoginPage />;
  // }

  return children;
};

MsalAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MsalAuthProvider;
